import get from 'lodash/get';
import { defineMessages } from 'react-intl';
import uniqBy from 'lodash/uniqBy';
import { buildArtistShopUrl } from '../../lib/doSearch';
import { CATEGORIES, FALLBACK } from './constants';
import config from '../../lib/config';

const messages = defineMessages({
  showAllCategory: 'Search in {category}',
  showAll: 'Show results for "{term}"',
});

const TYPEAHEAD_SERVICE_URL = config('typeaheadServiceBaseUrl') || '/typeahead/';

// Determines if a product context object is valid
// i.e. has an ia_code and a label
export const validProductContext = (productContext) => {
  let id = get(productContext, 'id', '');
  let label = get(productContext, 'label', '');

  if (typeof id === 'string') id = id.trim();
  if (typeof label === 'string') label = label.trim();

  return !!(id && label);
};

// Given an existing product context and a new item selected
// from typeahead:
//
// Determines the correct product context to send the user to
export const makeProductContextForSearch = (productContext, selectedItem) => {
  // If there's already an product context, return it.
  if (validProductContext(productContext)) return productContext;

  const id = get(selectedItem, 'ia_code', '');
  const label = get(selectedItem, 'ia_display_name', '');

  return { id, label };
};

export const handleFetchError = (e) => {
  // Ignore if we aborted the fetch
  if (e.name === 'AbortError') return;

  console.error(e);
};

export const formatData = (data, type, iaCode, max) => {
  let typedData = [...data];
  if (iaCode) typedData = uniqBy(typedData, 'keywords');
  typedData = typedData.slice(0, max);
  typedData = typedData.map((item, index) => ({ ...item, type, position: index.toString() }));
  return typedData;
};

export const formatArtists = (data, type, baseUrl, max) => {
  return data.slice(0, max)
    .map(artist => ({
      ...artist,
      keywords: artist.label,
      type,
      url: buildArtistShopUrl(baseUrl, artist.artist_name),
    }));
};

export const formatRecent = (data, type, fn) => {
  return data.map(item => ({
    label: item.term,
    keywords: item.term,
    ia_code: get(item, 'context.id'),
    ia_display_name: get(item, 'context.label'),
    type,
    secondaryAction: () => {
      fn(item.term);
    },
    ...(item.originalItem || {}),
  }));
};

export const formatShowAll = (data, term, iaCode, intl) => {
  let categories = [];
  if (term && iaCode) {
    // Limit to items with ia_codes and ia_display_name
    categories = data.filter(item => (
      item.ia_code && item.ia_display_name
    ));

    categories = uniqBy(categories, 'ia_code'); // no duplicate ia_codes
    categories = categories.map((item, index) => ({
      ...item,
      // All links search for "term"
      keywords: term,
      // Show all "fox" Stickers
      linkLabel: intl.formatMessage(
        messages.showAllCategory,
        { term, category: item.ia_display_name },
      ),
      type: CATEGORIES,
      position: index,
    }));
    categories = categories.slice(0, 3);
  }

  const fallback = (term !== '')
    ? [{
      keywords: term,
      linkLabel: intl.formatMessage(messages.showAll, { term }),
      type: FALLBACK,
      position: 0,
    }]
    : [];

  return { fallback, categories };
};

export const typeaheadRequest = async (params, abortController) => {
  const paramsString = Object.keys(params)
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`).join('&');

  const url = `${TYPEAHEAD_SERVICE_URL}?${paramsString}`;

  const response = await fetch(
    url,
    {
      method: 'GET',
      ...(abortController ? { signal: abortController.signal } : {}),
    },
  );
  return response.json();
};

export const AnalyticsLocalStorageKeys = {
  RefContextType: 'ref_ctx_type',
  RefContextIndex: 'ref_ctx_index',
  RefContextLabel: 'ref_ctx_label',
};

export const AnalyticsContextTypes = {
  TrendingTypeahead: 'trending-typeahead',
  PopularTypeahead: 'popular-typeahead',
  RecentTypeahead: 'recent-typeahead',
  FallbackTypeahead: 'fallback-typeahead',
  CategoriesTypeahead: 'categories-typeahead',
  ArtistsTypeahead: 'artists-typeahead',
  SearchBox: 'search-box',
};

export const AnalyticsStorage = {
  isValidKey(key) {
    return Object.values(AnalyticsLocalStorageKeys).includes(key);
  },

  getValue(key) {
    if (!this.isValidKey(key)) {
      console.error(
        `Unable to get analytics local storage value, invalid key provided: "${key}"`,
      );
      return null;
    }

    try {
      return localStorage.getItem(key);
    } catch (error) {
      console.error(`Error getting localStorage for key "${key}":`, error);
      return null;
    }
  },

  setValue(key, value) {
    if (!this.isValidKey(key)) {
      console.error(
        `Unable to set analytics local storage value, invalid key provided: "${key}"`,
      );
      return;
    }

    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.error(
        `Error setting value for key "${key}" in localStorage:`,
        error,
      );
    }
  },
};

import React from 'react';
import get from 'lodash/get';
import Box from '../../../../../Box';
import Text from '../../../../../Text';
import TextLink from '../../../../../TextLink';
import Grid from '../../../../../Grid';
import ArrowUpRight from '../../../../../Icons/ArrowUpRight';
import Carousel, { Slide } from '../../../../../Carousel';
import { DESKTOP, SMALL } from '../../../../../constants';
import styles from './styles.scss';

export default function StaticLinks(props) {
  const popularProducts = get(props, 'data.popularProducts.items', []);
  const quickLinks = get(props, 'data.quickLinks.items', []);
  const otherLinks = get(props, 'data.otherLinks.items', []);

  const popularProductsTitle = get(props, 'data.popularProducts.title', '');
  const quickLinksTitle = get(props, 'data.quickLinks.title', '');

  return (
    <React.Fragment>
      <Box marginBottom="xs">
        <Text type="display6">{popularProductsTitle}</Text>
      </Box>
      <Box className={styles.section} data-testid="ds-typeahead-static-products">
        <Grid columns={props.profile === DESKTOP ? 3 : 1}>
          {popularProducts.map(item => (
            <a href={item.link} className={styles.link} key={item.title}>
              {props.profile === DESKTOP
                ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    className={styles.popularProduct}
                    style={item.style}
                  >
                    <Box paddingTop="m" paddingRight="m" paddingLeft="m">
                      <Text type="display6">{item.title}</Text>
                    </Box>
                    <Box
                      padding="m"
                      className={styles.popularProductImageDesktop}
                      style={{ backgroundImage: `url(${item.image})` }}
                    />
                  </Box>
                )
                : (
                  <Box
                    className={styles.popularProductWrapper}
                    style={{
                      background: item.style.background,
                    }}
                  >
                    <Box
                      padding="m"
                      display="flex"
                      alignItems="center"
                      className={styles.popularProductImageMobile}
                      style={{ backgroundImage: `url(${item.mobileImage || item.image})` }}
                    >
                      <Text type="display6">{item.title}</Text>
                    </Box>
                  </Box>
                )}
            </a>
          ))}
        </Grid>
      </Box>

      <Box marginBottom="xs" marginTop="xl">
        <Text type="display6">{quickLinksTitle}</Text>
      </Box>

      <Box className={styles.section} data-testid="ds-typeahead-quick-links">
        {props.profile === DESKTOP
          ? (
            <Grid columns={3}>
              {quickLinks.map(item => (
                <a href={item.link} className={styles.link} key={item.title}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    className={styles.quickLinksWrapper}
                    style={item.style}
                  >
                    <Box paddingTop="m" paddingLeft="m" paddingRight="m" paddingBottom="xs">
                      <Text type="display6">{item.title}</Text>
                    </Box>
                    <Box
                      padding="m"
                      className={styles.quickLinkGrid}
                      style={{ backgroundImage: `url(${item.image})` }}
                    />
                  </Box>
                </a>
              ))}
            </Grid>
          )
          : (
            <Carousel slidesPerView={2}>
              {quickLinks.map(item => (
                <Slide key={item.title}>
                  <a href={item.link} className={styles.link}>
                    <Box marginBottom="m" flex="1">
                      <Box
                        className={styles.quickLinksCarouselWrapper}
                        style={{ background: item.style.background }}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          className={styles.quickLinkCarousel}
                          style={{ backgroundImage: `url(${item.mobileImage || item.image})` }}
                        >
                          <Box padding="m" className={styles.slideTitle}>
                            <Text type="display6">{item.title}</Text>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </a>
                </Slide>
              ))}
            </Carousel>
          )}
      </Box>

      <Box
        marginTop={props.profile === DESKTOP ? 2 : 0}
        marginBottom="xl"
        className={styles.section}
        data-testid="ds-typeahead-support-links"
      >
        <Grid columns={props.profile === DESKTOP ? 2 : 1}>
          {otherLinks.map(item => (
            <Box display="flex" key={item.title}>
              <Box paddingRight="xs" flex="0 1 auto">
                <ArrowUpRight size={SMALL} />
              </Box>
              <Box flex="1 1 auto">
                <TextLink className={styles.link} href={item.link} external>
                  <Text>{item.title}</Text>
                </TextLink>
              </Box>
            </Box>
          ))}
        </Grid>

      </Box>
    </React.Fragment>
  );
}

import React from 'react';
import Highlighter from 'react-highlight-words';
import cnames from 'classnames';
import { defineMessages } from 'react-intl';
import * as constants from '../../../../constants';
import SearchIcon from '../../../../Icons/Search';
import Button from '../../../../Button';
import HistoryIcon from '../../../../Icons/History';
import TrendingUpIcon from '../../../../Icons/TrendingUp';
import CloseIcon from '../../../../Icons/Close';
import Avatar from '../../../../Avatar';
import ChevronRightIcon from '../../../../Icons/ChevronRightBig';
import Box from '../../../../Box';
import Text from '../../../../Text';
import styles from './styles.scss';
import {
  RECENT,
  TRENDING,
  POPULAR,
  ARTISTS,
  CATEGORIES,
  FALLBACK,
} from '../constants';
import { AnalyticsContextTypes, AnalyticsLocalStorageKeys, AnalyticsStorage } from '../utils';

const messages = defineMessages({
  labelIn: 'in {in}',
});

const MenuItemIcon = ({ type, size, item }) => {
  switch (type) {
    case RECENT:
      return <HistoryIcon size={size} />;
    case TRENDING:
      return <TrendingUpIcon size={size} />;
    case ARTISTS:
      return (
        <Avatar
          src={item.artist_avatar_url}
          size={constants.TINY}
          alt={`Avatar for ${item.label}`}
        />
      );
    default:
      return <SearchIcon size={size} />;
  }
};

const MenuSection = ({
  withProductContext,
  heading,
  highlight = false,
  items,
  getItemProps: downshiftItemPropGetter,
  highlightedIndex,
  searchWords,
  offset = 0,
  doSearch,
  onSearchClose,
  logItemClicked,
  intl,
  closeMenu,
  onTypeaheadResultClick,
}) => {
  const highlightClassName = index => cnames(styles.link, {
    [styles.highlighted]: highlightedIndex === index,
  });

  // Either rely on Downshift or perform our own doSearch when clicked
  const getItemProps = typeof downshiftItemPropGetter === 'function' ? (
    (options) => {
      const baseProps = downshiftItemPropGetter(options);

      return {
        ...baseProps,
        onClick: (event) => {
          if (options.item && options.item.type) {
            if (onTypeaheadResultClick) {
              onTypeaheadResultClick(options.item.type);
            } else {
              switch (options.item.type) {
                case TRENDING:
                  AnalyticsStorage.setValue(
                    AnalyticsLocalStorageKeys.RefContextType,
                    AnalyticsContextTypes.TrendingTypeahead,
                  );
                  break;
                case RECENT:
                  AnalyticsStorage.setValue(
                    AnalyticsLocalStorageKeys.RefContextType,
                    AnalyticsContextTypes.RecentTypeahead,
                  );
                  break;
                case POPULAR:
                  AnalyticsStorage.setValue(
                    AnalyticsLocalStorageKeys.RefContextType,
                    AnalyticsContextTypes.PopularTypeahead,
                  );
                  break;
                case ARTISTS:
                  AnalyticsStorage.setValue(
                    AnalyticsLocalStorageKeys.RefContextType,
                    AnalyticsContextTypes.ArtistsTypeahead,
                  );
                  break;
                case CATEGORIES:
                  AnalyticsStorage.setValue(
                    AnalyticsLocalStorageKeys.RefContextType,
                    AnalyticsContextTypes.CategoriesTypeahead,
                  );
                  break;
                case FALLBACK:
                  AnalyticsStorage.setValue(
                    AnalyticsLocalStorageKeys.RefContextType,
                    AnalyticsContextTypes.FallbackTypeahead,
                  );
                  break;
                default:
                  break;
              }
            }
          }

          if (baseProps.onClick) {
            baseProps.onClick(event);
          }
          if (typeof closeMenu === 'function') {
            closeMenu();
          }
        },
      };
    }
  ) : ({ item }) => ({
    onClick: () => {
      logItemClicked({
        keywords: item.keywords,
        itemIaCode: item.ia_code,
        itemType: item.type,
        itemPosition: item.position,
      });

      if (onTypeaheadResultClick) {
        onTypeaheadResultClick(item.type);
      }

      doSearch(item.keywords, item.ia_code);

      onSearchClose();
    },
  });

  return (
    <React.Fragment>
      {
        heading && (
          <li>
            <Box marginBottom="xs" paddingLeft="xxs">
              <Text type="display6">{ heading }</Text>
            </Box>
          </li>
        )
      }
      {
          items.map((item, localIndex) => {
            const index = localIndex + offset;

            const {
              ia_display_name: iaDisplayName,
              keywords,
              linkLabel,
              secondaryAction,
              type,
            } = item;

            const productLabel = !withProductContext && iaDisplayName && !linkLabel ? (
              intl.formatMessage(messages.labelIn, { in: iaDisplayName })
            ) : '';

            const hasSearchWords = (
              Array.isArray(searchWords) && !!searchWords.filter(Boolean).length
            );

            let displayText = <Text>{ keywords }</Text>;
            if (hasSearchWords && highlight) {
              displayText = (
                <Text type="display5">
                  <Highlighter
                    highlightClassName={styles.highlightedDifference}
                    searchWords={searchWords}
                    autoEscape
                    textToHighlight={keywords}
                  />
                </Text>
              );
            }

            if (linkLabel) {
              displayText = (
                <Text>
                  <Highlighter
                    highlightClassName={styles.highlightedTerm}
                    searchWords={[iaDisplayName]}
                    autoEscape
                    textToHighlight={linkLabel}
                  />
                </Text>
              );
            }

            return (
              <li
                className={highlightClassName(index)}
                key={`${item.label}${index}`} // eslint-disable-line react/no-array-index-key
                {...getItemProps({ item, index })}
                data-testid="ds-typeahead-menu-item"
              >
                <Box
                  display="flex"
                  alignItems={!productLabel || linkLabel ? 'center' : 'baseline'}
                  padding="xxs"
                  marginBottom="xs"
                >
                  {
                    !linkLabel && (
                      <Box marginRight="xs" display="flex" alignItems="center">
                        <MenuItemIcon type={type} size={constants.SMALL} item={item} />
                      </Box>
                    )
                  }

                  <Box display="flex" flexDirection="column" style={{ minWidth: 0 }}>
                    <Box className={styles.overflowEllipsis}>
                      { displayText }
                    </Box>

                    {
                      productLabel && (
                        <Text type="caption">{ productLabel }</Text>
                      )
                    }
                  </Box>

                  {
                    linkLabel && <Box style={{ marginLeft: 'auto', alignSelf: 'center' }}><ChevronRightIcon /></Box>
                  }
                  {
                    secondaryAction && (
                      <Box style={{ marginLeft: 'auto', alignSelf: 'center' }}>
                        <Button
                          size={constants.SMALL}
                          circle
                          onClick={(e) => {
                            e.stopPropagation();
                            secondaryAction();
                          }}
                        >
                          <CloseIcon />
                        </Button>
                      </Box>
                    )
                  }
                </Box>
              </li>
            );
          })
      }
    </React.Fragment>
  );
};

const MenuSectionLoading = ({ heading, itemCount }) => (
  <React.Fragment>
    <li>
      <Box marginBottom="xs">
        { heading && <Text loading type="display6">{ heading }</Text> }
      </Box>
    </li>

    {
      new Array(itemCount).fill('').map((i, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <Box
            display="flex"
            alignItems="center"
            padding="xxs"
          >
            <Text loading>
              ________________________
            </Text>
          </Box>
        </li>
      ))
    }
  </React.Fragment>
);

export default MenuSection;
export { MenuSectionLoading };

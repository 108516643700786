const config = {
  production: {
    typeaheadServiceBaseUrl: '/typeahead/',
  },
  staging: {
    typeaheadServiceBaseUrl: '/typeahead/',
  },
  development: {
    typeaheadServiceBaseUrl: 'https://www.redbubble-staging.com/typeahead/',
  },
};

export default function get(key) {
  const env = process.env.NODE_ENV;

  return Object.keys(config[env] || {}).length
    ? config[env][key]
    : undefined;
}

import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, injectIntl } from 'react-intl';
import enMessages from '../../translations/en-US.json';
import deMessages from '../../translations/de-DE.json';
import * as ProductNavExports from '..';
import Button from '../../Button';
import Drawer from '../../Drawer';
import * as constants from '../../constants';

import items from './items.json';

const { default: ProductNav, MobileProductNav, StaticMobileProductNav } = ProductNavExports;

const messages = {
  en: enMessages,
  de: deMessages,
};

const DesktopProductNavComponent = injectIntl(ProductNav);
const MobileProductNavComponent = injectIntl(MobileProductNav);

const basicDesktopProductNavExample = {
  name: 'Basic Usage for DesktopProductNav',
  description: '',
  muted: true,
  render: () => (
    <IntlProvider locale="en" messages={messages.en}>
      <DesktopProductNavComponent items={items.en} />
    </IntlProvider>
  ),
  code: `
<ProductNav items={...} />
  `,
};

const HamburgerButton = ({ handleClick }) => {
  return (
    <Button type="button" onClick={handleClick}>
      <span role="img" aria-label="burger">🍔</span>
    </Button>
  );
};

HamburgerButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

const userInfo = {
  avatar: 'https://redbubble-static.redbubble.net/assets/rb-default-avatar.140x140-74f9462fbe8b15d59e89ceee5733a3ca6eada009a9db21b08fa72598fa820909.png',
  username: 'hpotter',
  locale: 'en',
  country: 'AU',
  isLoggedIn: true,
};

const userAccount = {
  isArtist: false,
};

const basicMobileProductNavExample = {
  name: 'Basic Mobile Product Nav Example',
  description: 'Mobile Product Nav is in beta version.',
  render: () => (
    <IntlProvider locale={userInfo.locale} messages={messages.en}>
      <Drawer
        accessibleTitle="Product Nav"
        trigger={({ onClick }) => (
          <HamburgerButton handleClick={onClick} />
        )}
        size={constants.LARGE}
        from={constants.LEFT}
      >
        <MobileProductNavComponent
          baseUrl="/"
          userInfo={{ ...userInfo, isLoggedIn: false }}
          userAccount={userAccount}
          items={items.en}
        />
      </Drawer>
    </IntlProvider>
  ),
  code: `
<IntlProvider locale={userInfo.locale} messages={messages.en}>
  <Drawer
    accessibleTitle='Product Nav'
    trigger={({ onClick }) => (
      <HamburgerButton handleClick={onClick} />
    )}
    size={constants.LARGE}
    from={constants.LEFT}
  >
    <MobileProductNavComponent
      baseUrl="/"
      userInfo={{ ...userInfo, isLoggedIn: false }}
      userAccount={userAccount}
      items={...}
    />
  </Drawer>
</IntlProvider>
  `,
};

const nonShoppingExperienceMobileProductNavExample = {
  name: 'Non Shopping Experience Mobile Product Nav Example',
  description: 'Mobile Product Nav displayed in a non shopping experience.',
  render: () => (
    <IntlProvider locale={userInfo.locale} messages={messages.en}>
      <Drawer
        accessibleTitle="Product Nav"
        trigger={({ onClick }) => (
          <HamburgerButton handleClick={onClick} />
        )}
        size={constants.LARGE}
        from={constants.LEFT}
      >
        <MobileProductNavComponent
          baseUrl="/"
          userInfo={{ ...userInfo, isLoggedIn: false }}
          userAccount={userAccount}
        />
      </Drawer>
    </IntlProvider>
  ),
  code: `
<IntlProvider locale={userInfo.locale} messages={messages.en}>
  <Drawer
    accessibleTitle='Product Nav'
    trigger={({ onClick }) => (
      <HamburgerButton handleClick={onClick} />
    )}
    size={constants.LARGE}
    from={constants.LEFT}
  >
    <MobileProductNavComponent
      baseUrl="/"
      userInfo={{ ...userInfo, isLoggedIn: false }}
      userAccount={userAccount}
    />
  </Drawer>
</IntlProvider>
  `,
};

const loggedInMobileProductNavExample = {
  name: 'Logged in User Mobile Product Nav Example',
  description: 'Mobile Product Nav is in beta version.',
  render: () => (
    <IntlProvider locale={userInfo.locale} messages={messages.en}>
      <Drawer
        accessibleTitle="Product Nav"
        trigger={({ onClick }) => (
          <HamburgerButton handleClick={onClick} />
        )}
        size={constants.LARGE}
        from={constants.LEFT}
      >
        <MobileProductNavComponent
          baseUrl="/"
          userInfo={userInfo}
          userAccount={userAccount}
          items={items.en}
        />
      </Drawer>
    </IntlProvider>
  ),
  code: `
<IntlProvider locale={userInfo.locale} messages={messages.en}>
  <Drawer
    accessibleTitle='Product Nav'
    trigger={({ onClick }) => (
      <HamburgerButton handleClick={onClick} />
    )}
    size={constants.LARGE}
    from={constants.LEFT}
  >
    <MobileProductNavComponent
      baseUrl="/"
      userInfo={userInfo}
      userAccount={userAccount}
      items={...}
    />
  </Drawer>
</IntlProvider>
  `,
};

const germanDesktopProductNavExample = {
  name: 'Desktop Nav in German',
  description: '',
  muted: true,
  render: () => (
    <IntlProvider locale="de" messages={messages.de}>
      <DesktopProductNavComponent items={items.de} />
    </IntlProvider>
  ),
  code: `
<ProductNav items={...} />
  `,
};

const germanMobileProductNavExample = {
  name: 'Mobile Product Nav in German',
  description: '',
  render: () => (
    <IntlProvider locale="de" messages={messages.de}>
      <Drawer
        accessibleTitle="Product Nav"
        trigger={({ onClick }) => (
          <HamburgerButton handleClick={onClick} />
        )}
        size={constants.LARGE}
        from={constants.LEFT}
      >
        <MobileProductNavComponent
          baseUrl="/"
          userInfo={userInfo}
          userAccount={userAccount}
          items={items.de}
        />
      </Drawer>
    </IntlProvider>
  ),
  code: `
<IntlProvider locale="de" messages={messages.de}>
  <Drawer
    accessibleTitle='Product Nav'
    trigger={({ onClick }) => (
      <HamburgerButton handleClick={onClick} />
    )}
    size={constants.LARGE}
    from={constants.LEFT}
  >
    <MobileProductNavComponent
      baseUrl="/"
      userInfo={userInfo}
      userAccount={userAccount}
      items={...}
    />
  </Drawer>
</IntlProvider>
  `,
};

const staticMobileProductNavExample = {
  name: 'StaticMobileProductNav',
  description: '',
  muted: true,
  render: () => (
    <StaticMobileProductNav items={items.en} />
  ),
  code: `
<ProductNav items={...} />
  `,
};

const peekingDesktopProductNavExample = {
  name: 'Peeking product navigation when scrolling',
  description: 'When the `peeking` prop is utilized, navigation hides during downward scrolling and reappears upon upward scrolling. Additionally, you have the flexibility to provide a `topOffset` option, allowing you to precisely define the initial vertical placement of the absolutely positioned navigation. This positioning is subsequently transitioned to a fixed placement, ensuring a fluid user experience. Demo with `fixed` and `absolute` positions is tricky, but you can see a working example on www.redbubble.com or the playroom.',
  muted: true,
  render: () => null,
  code: `
    <DesktopProductNavComponent items={...} peeking, topOffset="88px />
  `,
};

export default {
  title: ProductNav.displayName,
  description: 'A navigation menu for shopping product categories.',
  slug: 'product-nav',
  exports: Object.keys(ProductNavExports),
  examples: [
    basicMobileProductNavExample,
    nonShoppingExperienceMobileProductNavExample,
    loggedInMobileProductNavExample,
    basicDesktopProductNavExample,
    germanDesktopProductNavExample,
    germanMobileProductNavExample,
    staticMobileProductNavExample,
    peekingDesktopProductNavExample,
  ],
};

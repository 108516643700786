import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import cnames from 'classnames';
import getElementType from '../utils/getElementType';
import ContextVerticalIcon from '../Icons/ContextVertical';
import Dropdown, { DropdownSection, DropdownItem, DropdownLink } from '../Dropdown';
import Button from '../Button';
import * as constants from '../constants';
import Box from '../Box';
import Card from '../Card';
import Image from '../Image';
import Skeleton from '../Skeleton';
import Text from '../Text';
import useTracking, { trackingShape, EVENT_CLICK, EVENT_OPEN, EVENT_DISMISS } from '../hooks/useTracking';
import styles from './styles.scss';

export const events = { EVENT_CLICK, EVENT_OPEN, EVENT_DISMISS };

const stopEvent = (e) => {
  e.preventDefault();
  e.stopPropagation();
};


const HideLinkMediaCover = ({
  hideLink,
  link,
  children,
}) => {
  return (
    <>
      {hideLink ? (
        <div
          tabIndex={0}
          role="button"
          className={cnames(styles.link, styles.hideLink)}
          onAuxClick={(ev) => {
            if (ev.button === 1) {
              window.open(link, '_blank');
            } else if (ev.button === 4) {
              window.history.go();
            } else if (ev.button === 5) {
              window.history.back();
            }
          }}
          onClick={(ev) => {
            if (
              ev.ctrlKey
              || ev.shiftKey
              || ev.metaKey
              || (ev.button && ev.button === 1)
            ) {
              ev.preventDefault();
              window.open(link, '_blank');
              return;
            }
            window.location.href = link || '';
          }}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </>
  );
};

const childrenContent = (
  imageRender,
  defaultImageProps,
  imageSrcSet,
  imageSrcSetInitial,
  imageSrc,
  imageAlt,
  imageRatio,
  loadOnVisible,
  loading,
) => {
  if (typeof imageRender === 'function') {
    return imageRender({
      imageProps: defaultImageProps,
    });
  }

  if (loading) {
    return <Skeleton roundedCorners={false} ratio={imageRatio} />;
  }

  return (
    <Image
      src={imageSrcSet ? imageSrcSetInitial[0] : imageSrc}
      alt={imageAlt}
      srcSet={imageSrcSet}
      ratio={imageRatio}
      loadOnVisible={loadOnVisible}
      {...defaultImageProps}
    />
  );
};

const MediaCard = (props) => {
  const {
    primaryActionSlot,
    secondaryActions,
    profile,
    imageSrc,
    imageSrcSet,
    imageRatio,
    imageAlt,
    title,
    loading,
    caption,
    transparent,
    children,
    loadOnVisible,
    imageRender,
    imageCover,
    backgroundColor,
    tracking,
    hideLink,
    href,
    ...rest // eslint-disable-line
  } = props;
  const { track, TrackingContextProvider } = useTracking(tracking, 'MediaCard');

  if (!imageSrc && !imageSrcSet && !imageRender && !loading) return null;

  const Element = getElementType(MediaCard, props);

  // use to remove horizontal padding if the card is transparent
  const hPadding = transparent ? 0 : 0.5;

  // get the image urls from the srcSet
  const imageSrcSetInitial = imageSrcSet && imageSrcSet.match(/(https?:\/\/[^\s]+)/g);

  const hasSecondaryActions = Array.isArray(secondaryActions) && !!secondaryActions.length;

  // default props for image
  const defaultImageProps = {
    fluid: true,
    roundedCorners: transparent,
  };

  const imageContainerClasses = cnames(styles.imageContainer, {
    [styles.roundedCorners]: transparent,
  });

  return (
    <TrackingContextProvider>
      <HideLinkMediaCover hideLink={hideLink} link={href}>
        <Element
          {...rest}
          href={hideLink ? undefined : href}
          className={styles.link}
          onClick={(e) => {
            track(EVENT_CLICK);
            if (typeof rest.onClick === 'function') {
              rest.onClick(e);
            }
          }}
        >
          <Card transparent={transparent} overflowHidden={false}>
            {
            primaryActionSlot && (
              <Box
                onClick={stopEvent}
                className={styles.primaryActionSlot}
              >
                { primaryActionSlot }
              </Box>
            )
          }
            <Box className={imageContainerClasses}>
              {
              childrenContent(
                imageRender,
                defaultImageProps,
                imageSrcSet,
                imageSrcSetInitial,
                imageSrc,
                imageAlt,
                imageRatio,
                loadOnVisible,
                loading,
              )
            }
            </Box>
            { (title || caption || children) ? (
              <Box display="flex">
                <Box
                  padding="s"
                  paddingLeft={hPadding}
                  paddingRight={hPadding}
                  style={{
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  {
                  hasSecondaryActions && (
                    <Box style={{ position: 'absolute', top: 0, right: transparent ? -8 : 0 }}>
                      <Box paddingTop="xs" paddingRight="xs">
                        <Dropdown
                          tracking={tracking ? 'context-menu' : undefined}
                          profile={profile}
                          trigger={(triggerProps, ref) => (
                            <Button
                              circle
                              iconAfter={<ContextVerticalIcon size={constants.MEDIUM} />}
                              size={constants.SMALL}
                              {...triggerProps}
                              ref={ref}
                              onClick={(e) => {
                                stopEvent(e);

                                if (typeof triggerProps.onClick === 'function') {
                                  triggerProps.onClick(e);
                                }
                              }}
                            />
                          )}
                        >
                          <DropdownSection hideTitle title={`Context menu ${title && `for ${title}`}`}>
                            {
                              secondaryActions.map(({ render, ...actionProps }) => {
                                let itemProps = { ...actionProps, children: render };

                                if (typeof itemProps.onClick === 'function') {
                                  itemProps = {
                                    ...itemProps,
                                    onSelect: itemProps.onClick,
                                    onClick: undefined,
                                  };
                                }

                                const Item = itemProps.href ? DropdownLink : DropdownItem;

                                return <Item key={shortid.generate()} {...itemProps} />;
                              })
                            }
                          </DropdownSection>
                        </Dropdown>
                      </Box>
                    </Box>
                  )
                }
                  <Box
                    className={styles.disableLineHeight}
                    paddingRight={hasSecondaryActions ? 2 : 0}
                  >
                    { title && (
                    <Text lineClamp={1} type="body2" display="block" loading={loading}>{title}</Text>
                    )}
                    { typeof caption === 'string' ? caption && (
                      <Box className={styles.disableLineHeight} marginTop="xxs">
                        <Text muted lineClamp={1} type="body2" loading={loading}>{caption}</Text>
                      </Box>
                    ) : caption }
                  </Box>
                  { children && (
                  <Box marginTop={(title || caption) ? 0.50 : 0}>
                    { children }
                  </Box>
                  )}
                </Box>
              </Box>
            ) : null }
          </Card>
        </Element>
      </HideLinkMediaCover>
    </TrackingContextProvider>
  );
};

// Proptypes for imageRatio
const RATIO_TYPES = [constants.RATIO_SQUARE, constants.RATIO_PORTRAIT];
const RATIO_MODES = [constants.RATIO_COVER, constants.RATIO_CONTAIN];

const RATIO_PROP_TYPE = PropTypes.oneOfType([
  PropTypes.oneOf(RATIO_TYPES),
  PropTypes.shape({
    type: PropTypes.oneOf(RATIO_TYPES),
    mode: PropTypes.oneOf(RATIO_MODES),
  }),
]);

MediaCard.propTypes = {
  /**
    * Defines what element should wrap the component
  */
  element: PropTypes.string,
  /**
    * Give the component an image url
  */
  imageSrc: PropTypes.string,
  /**
    * Sets the images to use as srcSets. Provide as array
  */
  imageSrcSet: PropTypes.string,
  /**
    * Render prop that yields render control of the image region to the consumer
  */
  imageRender: PropTypes.func,
  /**
    * Sets the alt tag of the image
  */
  imageAlt: PropTypes.string,
  /**
    * Forces a ratio on the image and adds space around it
  */
  imageRatio: RATIO_PROP_TYPE,
  /**
    * Add the most important piece of text
  */
  title: PropTypes.string,
  /**
    * Add a piece of text or inline component that is less important
  */
  caption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  /**
    * Make the Card appear transparent and without a drop shadow
  */
  transparent: PropTypes.bool,
  /**
    * Renders the MediaCard in a loading state
  */
  loading: PropTypes.bool,
  /**
    * The (optional) contents that appear after the title and caption.
    */
  children: PropTypes.node,
  /**
    * Is this MediaCard being rendered on Mobile or Desktop?
    */
  profile: PropTypes.oneOf([
    constants.DESKTOP,
    constants.MOBILE,
  ]),
  /**
    * If provided, this action will be rendered in the top right of the card
    */
  primaryActionSlot: PropTypes.node,
  /**
    * If provided, these actions will be made available in a context menu
    */
  secondaryActions: PropTypes.arrayOf(PropTypes.shape({})),
  /**
    * Configuration for the loadOnVisible behaviour of the Image
    */
  loadOnVisible: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      enabled: PropTypes.bool,
      placeholder: PropTypes.string,
      offsetTopBoundaryByPx: PropTypes.number,
    }),
  ]),
  tracking: trackingShape,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
  */
  styles: PropTypes.shape({}),
};

MediaCard.defaultProps = {
  element: 'a',
  loading: false,
  profile: constants.MOBILE,
  imageSrc: '',
  imageAlt: '',
  imageSrcSet: null,
  imageRender: null,
  imageRatio: null,
  primaryActionSlot: null,
  secondaryActions: [],
  title: '',
  caption: '',
  transparent: false,
  children: null,
  loadOnVisible: true,
  tracking: null,
};

MediaCard.displayName = 'MediaCard';

export default MediaCard;
